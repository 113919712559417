export interface Loading {
	loading?: boolean;
	redirecting?: boolean;
	formLoading?: boolean;
	loadingError?: LoadingError;
}

export interface LoadingError {
	message?: string;
	response?: any;
}

export enum LoadingActionTypes {
	LOADING = '@@loading/LOADING',
	FORM_LOADING = '@@loading/FORM_LOADING',
	FORM_LOADED = '@@loading/FORM_LOADED',
	LOADED = '@@loading/LOADED',
	LOADING_ERROR = '@@loading/LOADING_ERROR',
	REDIRECTING = '@@loading/REDIRECTING'
}
