import { all, fork, put, takeEvery, select, take } from 'redux-saga/effects';
import { AppState } from '../index';
import { Profile } from '../profile/types';
import { ApplicationApiModel } from '../../api/applicationDataApi';
import { ProgressiveFieldsUpdated, ProgressiveFieldsUpdate } from './actions';
import {
	getApplicationRelatedFields,
	getNotFilledInFields,
	removeExtraFields,
} from './ProgressiveFieldsService';
import {
	SetCountriesNeeded,
	SetStatesNeeded,
	GetStates,
	SetCallingCodesNeeded,
	SetTimezonesNeeded
} from '../location/actions';
import { formValueSelector, change } from 'redux-form';
import { channel } from 'redux-saga';
import {
	LocationApiCountry,
	LocationApiCountryState,
	LocationApiCallingCode,
	Timezone
} from '../../api/locationApi';
import { LocationActionTypes } from '../location/types';
import { ReferenceItemApiModel } from '../../api/referencesApi';
import { SelectFieldOption } from '../../components/Fields/FormFields/Components/SelectField/SelectField';
import { getType, Action } from 'typesafe-actions';
import { FetchData } from '../application/actions';
import { FetchUser } from '../profile/actions';
import { ReferencesActionTypes, Language } from '../references/types';
import {
	SetLanguagesNeeded,
	SetDepartmentsNeeded,
	SetAreasOfInterestNeeded,
	SetAreaOfInterestNeeded,
	SetCompanyTypesNeeded,
} from '../references/actions';
import { FormFieldType } from '../../components/Fields/FormFields/FormFieldType';
import { LabeledFieldUi } from '../../components/Fields/FormFields/Components/LabelField/LabelField';
import { FieldMetadata, FieldsMetadata } from '../fieldsMetadata/types';

const formName = 'DynamicForm';
const formSelector = formValueSelector(formName);
const onChangeFieldChannel = channel();

export function* updateUserRelatedFields() {
	const { application, profile, fieldsMetadata } = (yield select((state: AppState) => {
		return { application: state.application, profile: state.profile, fieldsMetadata: {...state.fieldsMetadata} };
	})) as { application: ApplicationApiModel; profile: Profile, fieldsMetadata: FieldsMetadata };

	let newFields: FieldMetadata[];
	if (!application || !profile) {
		newFields = [];
	} else {

		const appRelatedFields = getApplicationRelatedFields(application.fields, (profile.legal_info && profile.legal_info.application  && profile.legal_info.application.is_dip_privacy_policy_accepted) || false);
		const notFilledInFields = getNotFilledInFields(appRelatedFields, fieldsMetadata, profile);
		const userRelatedFields = removeExtraFields(notFilledInFields, profile, fieldsMetadata.fieldsObject);

		yield put(ProgressiveFieldsUpdate(userRelatedFields));

		const countriesNeeded: boolean = yield additionalResourcesNeeded(
			userRelatedFields,
			fieldsMetadata.fieldsObject.country,
			SetCountriesNeeded
		);

		const statesNeeded: boolean = yield additionalResourcesNeeded(
			userRelatedFields,
			fieldsMetadata.fieldsObject.state,
			SetStatesNeeded
		);

		yield additionalResourcesNeeded(
			userRelatedFields,
			fieldsMetadata.fieldsObject.timezone,
			SetTimezonesNeeded
		);

		yield additionalResourcesNeeded(
			userRelatedFields,
			fieldsMetadata.fieldsObject.calling_code,
			SetCallingCodesNeeded
		);

		yield additionalResourcesNeeded(
			userRelatedFields,
			fieldsMetadata.fieldsObject.language,
			SetLanguagesNeeded
		);

		yield additionalResourcesNeeded(
			userRelatedFields,
			fieldsMetadata.fieldsObject.department,
			SetDepartmentsNeeded
		);

		yield additionalResourcesNeeded(
			userRelatedFields,
			fieldsMetadata.fieldsObject.areas_of_interest,
			SetAreasOfInterestNeeded
		);

		yield additionalResourcesNeeded(
			userRelatedFields,
			fieldsMetadata.fieldsObject.area_of_interest,
			SetAreaOfInterestNeeded
		);

		yield additionalResourcesNeeded(
			userRelatedFields,
			fieldsMetadata.fieldsObject.company_type,
			SetCompanyTypesNeeded
		);

		if (countriesNeeded && statesNeeded) {
			const countryFields = userRelatedFields.filter(
				f => f.name === fieldsMetadata.fieldsObject.country.name
			);

			if (countryFields[0].value) {
				yield put(GetStates(countryFields[0].value as string));
			}
			countryFields[0].onChange = function onCountryChange(event, currentCountry) {

				onChangeFieldChannel.put(
					change(formName, fieldsMetadata.fieldsObject.state.name, '')
				);

				if (currentCountry) {
					// load states for current country
					onChangeFieldChannel.put(
						GetStates(currentCountry as string)
					);
				} else {
					// Clear states if country is cleared
					if (userRelatedFields.find(field => field.name === fieldsMetadata.fieldsObject.state.name) !== undefined) {
						(userRelatedFields.find(field => field.name === fieldsMetadata.fieldsObject.state.name) as FieldMetadata).options = [];
					}
				}
			};
		}

		newFields = userRelatedFields;
	}

	yield put(ProgressiveFieldsUpdate(newFields));
	yield put(ProgressiveFieldsUpdated());
}

function* additionalResourcesNeeded(
	userRelatedFields: FieldMetadata[],
	field: FieldMetadata,
	action: (needed: boolean) => Action
) {

	const fields = userRelatedFields.filter(f => f.name === field.name);
	const resourcesNeeded = fields.length > 0;

	yield put(action(resourcesNeeded));
	return resourcesNeeded;
}

function* settingCountryTranslations() {
	const { currentCulture, fieldsObject } = (yield select((state: AppState) => {
		return { currentCulture: state.cultures.currentCulture, fieldsObject: {...state.fieldsMetadata.fieldsObject} };
	})) as { currentCulture: string; fieldsObject: any };

	const translations: LocationApiCountry[] = yield select(
		(state: AppState) => state.location.countriesTranslations[currentCulture]
	);

	if (!translations) return;
	const userRelatedFields: FieldMetadata[] = yield select(
		(state: AppState) => [...state.progressiveFields.fields]
	);
	if (!userRelatedFields) return;

	const field = getFieldFromState(
		userRelatedFields,
		fieldsObject.country
	);
	if (!field) return;

	const countries: LocationApiCountry[] = yield select(
		(state: AppState) => state.location.countries
	);

	if (!countries || !countries.length) {
		field.options = [];
	} else {
		if (field.value && field.data_type === FormFieldType.Label) {
			const translationItem = translations.filter(
				t => t.alpha2 === field.value
			);
			translationItem.length &&
				(((field as unknown) as LabeledFieldUi).textValue =
					translationItem[0].name);
		} else {
			field.options = countries
				.map(item => {
					let translation = item.name;
					const translationItem = translations.filter(
						t => t.alpha2 === item.alpha2
					);
					translationItem.length &&
						(translation = translationItem[0].name);

					return {
						label: {
							defaultText: translation,
						},
						value: item.alpha2,
					};
				})
				.sort((a, b) => a.label.defaultText.localeCompare(b.label.defaultText));
		}
	}

	yield put(ProgressiveFieldsUpdate(userRelatedFields));
	yield put(ProgressiveFieldsUpdated());
}

function* settingStateTranslations() {
	const { userRelatedFields, fieldsObject } = (yield select((state: AppState) => {
		return { userRelatedFields: [...state.progressiveFields.fields], fieldsObject: {...state.fieldsMetadata.fieldsObject} };
	})) as { userRelatedFields: FieldMetadata[]; fieldsObject: any };

	if (!userRelatedFields) return;

	const field = getFieldFromState(userRelatedFields, fieldsObject.state);
	if (!field) return;

	let selectedCountryCode: string = yield select(
		(state: AppState) =>
			formSelector(state, fieldsObject.country.name) as string
	);
	if (!selectedCountryCode) {
		// try get from profile, not from form
		const countryCodeField = getFieldFromState(
			userRelatedFields,
			fieldsObject.country
		);

		if (countryCodeField) {
			selectedCountryCode = countryCodeField.value;
		} 
	}

	if (!selectedCountryCode) {
		field.options = [];
	} else {
		const states: LocationApiCountryState[] = yield select(
			(state: AppState) =>
				state.location.states && state.location.states[selectedCountryCode]
		);
		if (!states) return;

		field.options = states
			.map(item => {
				return {
					label: {
						defaultText: item.name,
					},
					value: item.id,
				};
			})
			.sort((a, b) => a.label.defaultText.localeCompare(b.label.defaultText));
	}

	yield put(ProgressiveFieldsUpdate(userRelatedFields));
	yield put(ProgressiveFieldsUpdated());
}

function* settingLanguages() {
	const { userRelatedFields, fieldsObject } = (yield select((state: AppState) => {
		return { userRelatedFields: [...state.progressiveFields.fields], fieldsObject: {...state.fieldsMetadata.fieldsObject} };
	})) as { userRelatedFields: FieldMetadata[]; fieldsObject: any };

	if (!userRelatedFields) return;

	const field = getFieldFromState(
		userRelatedFields,
		fieldsObject.language
	);
	if (!field) return;

	const languages: Language[] = yield select(
		(state: AppState) => state.references.languages
	);

	if (!languages) {
		field.options = [];
	} else {
		field.options = languages
			.map(item => {
				return {
					label: {
						defaultText: item.text,
					},
					value: item.id,
				};
			})
			.sort((a, b) => a.label.defaultText.localeCompare(b.label.defaultText));
	}

	yield put(ProgressiveFieldsUpdate(userRelatedFields));
	yield put(ProgressiveFieldsUpdated());
}

function* settingDepartments() {
	const { userRelatedFields, fieldsObject } = (yield select((state: AppState) => {
		return { userRelatedFields: [...state.progressiveFields.fields], fieldsObject: {...state.fieldsMetadata.fieldsObject} };
	})) as { userRelatedFields: FieldMetadata[]; fieldsObject: any };

	if (!userRelatedFields) return;

	const field = getFieldFromState(
		userRelatedFields,
		fieldsObject.department
	);
	if (!field) return;

	const departments: ReferenceItemApiModel[] = yield select(
		(state: AppState) => state.references.departments
	);

	if (!departments) {
		field.options = [];
	} else {
		field.options = departments.map(item => {
			return {
				label: {
					defaultText: item.text,
					translationKey: `references.departments.${item.id}`,
				},
				value: item.id,
			} as SelectFieldOption;
		});
	}

	yield put(ProgressiveFieldsUpdate(userRelatedFields));
	yield put(ProgressiveFieldsUpdated());
}

function* settingAreasOfInterest() {
	const { userRelatedFields, fieldsObject } = (yield select((state: AppState) => {
		return { userRelatedFields: [...state.progressiveFields.fields], fieldsObject: {...state.fieldsMetadata.fieldsObject} };
	})) as { userRelatedFields: FieldMetadata[]; fieldsObject: any };

	if (!userRelatedFields) return;

	const field = getFieldFromState(
		userRelatedFields,
		fieldsObject.areas_of_interest
	);
	if (!field) return;

	const areasOfInterest: ReferenceItemApiModel[] = yield select(
		(state: AppState) => state.references.areasOfInterest
	);

	if (!areasOfInterest) {
		field.options = [];
	} else {
		field.options = areasOfInterest.map(item => {
			return {
				label: {
					defaultText: item.text,
					translationKey: `references.interest-areas.${item.id}`,
				},
				value: item.id,
			} as SelectFieldOption;
		});
	}

	yield put(ProgressiveFieldsUpdate(userRelatedFields));
	yield put(ProgressiveFieldsUpdated());
}

function* settingAreaOfInterest() {
	const { userRelatedFields, fieldsObject } = (yield select((state: AppState) => {
		return { userRelatedFields: [...state.progressiveFields.fields], fieldsObject: {...state.fieldsMetadata.fieldsObject} };
	})) as { userRelatedFields: FieldMetadata[]; fieldsObject: any };

	if (!userRelatedFields) return;

	const field = getFieldFromState(
		userRelatedFields,
		fieldsObject.area_of_interest
	);
	if (!field) return;

	const areaOfInterest: ReferenceItemApiModel[] = yield select(
		(state: AppState) => state.references.areaOfInterest
	);

	if (!areaOfInterest) {
		field.options = [];
	} else {
		field.options = areaOfInterest.map(item => {
			return {
				label: {
					defaultText: item.text,
					translationKey: `references.interest-area.${item.id}`,
				},
				value: item.id,
			} as SelectFieldOption;
		});
	}

	yield put(ProgressiveFieldsUpdate(userRelatedFields));
	yield put(ProgressiveFieldsUpdated());
}

function* settingCompanyTypes() {
	const { userRelatedFields, fieldsObject, companyTypes } = (yield select((state: AppState) => {
		return { userRelatedFields: [...state.progressiveFields.fields], fieldsObject: {...state.fieldsMetadata.fieldsObject}, companyTypes: state.references.companyTypes };
	})) as { userRelatedFields: FieldMetadata[]; fieldsObject: any, companyTypes: ReferenceItemApiModel[] };

	if (!userRelatedFields) return;

	const field = getFieldFromState(
		userRelatedFields,
		fieldsObject.company_type
	);
	if (!field) return;

	if (!companyTypes) {
		field.options = [];
	} else {
		field.options = companyTypes.map(item => {
			return {
				label: {
					defaultText: item.text,
					translationKey: `references.company-types.${item.id}`,
				},
				value: item.id,
			} as SelectFieldOption;
		});
	}

	yield put(ProgressiveFieldsUpdate(userRelatedFields));
	yield put(ProgressiveFieldsUpdated());
}

function* settingCallingCodes() {
	const { currentCulture, fieldsObject } = (yield select((state: AppState) => {
		return { currentCulture: state.cultures.currentCulture, fieldsObject: {...state.fieldsMetadata.fieldsObject} };
	})) as { currentCulture: string; fieldsObject: any };

	const userRelatedFields: FieldMetadata[] = yield select(
		(state: AppState) => [...state.progressiveFields.fields]
	)

	if (!userRelatedFields) return;

	const field = getFieldFromState(
		userRelatedFields,
		fieldsObject.calling_code
	);
	if (!field) return;

	const callingCodes: LocationApiCallingCode[] = yield select(
		(state: AppState) => state.location.callingCodes && state.location.callingCodes[currentCulture]
	);

	if (!callingCodes) {
		field.options = [];
	} else {
		field.options = callingCodes
			.filter(c => c.callingCode !== '')
			.map(item => {
				return {
					label: {
						defaultText: `${item.countryName} (+${item.callingCode})`,
					},
					value: `${item.countryCode}+${item.callingCode}`,
				};
			})
			.sort((a, b) => a.label.defaultText.localeCompare(b.label.defaultText));
	}

	yield put(ProgressiveFieldsUpdate(userRelatedFields));
	yield put(ProgressiveFieldsUpdated());
}

function* settingTimezones() {

	const { userRelatedFields, fieldsObject } = (yield select((state: AppState) => {
		return { userRelatedFields: [...state.progressiveFields.fields], fieldsObject: {...state.fieldsMetadata.fieldsObject} };
	})) as { userRelatedFields: FieldMetadata[]; fieldsObject: any };

	if (!userRelatedFields) return;

	const field = getFieldFromState(
		userRelatedFields,
		fieldsObject.timezone
	);
	if (!field) return;

	const timezones: Timezone[] = yield select(
		(state: AppState) => state.location.timezones
	);

	if (!timezones) {
		field.options = [];
	} else {
		field.options = timezones
			.filter(c => c.id !== '')
			.map(item => {
				return {
					label: {
						defaultText: item.text,
					},
					value: item.id,
				};
			});
	}

	yield put(ProgressiveFieldsUpdate(userRelatedFields));
	yield put(ProgressiveFieldsUpdated());
}

function* watchOnChangeFieldChannelChannel() {
	// Needed because of no other way to use yield inside non-generator function which onChange is
	while (true) {
		const action: Action<any> = yield take(onChangeFieldChannel);
		yield put(action);
	}
}

function* watchForChangesInNeededFieldsAction() {
	yield takeEvery(
		[getType(FetchData.success), getType(FetchUser.success)],
		function*() {
			yield updateUserRelatedFields();
			yield settingCountryTranslations();
			yield settingStateTranslations();
			yield settingLanguages();
			yield settingDepartments();
			yield settingAreasOfInterest();
			yield settingAreaOfInterest();
			yield settingCompanyTypes();
			yield settingCallingCodes();
			yield settingTimezones();
		}
	);
}

function* watchContriesLoadAction() {
	yield takeEvery(
		[
			LocationActionTypes.GET_COUNTRIES_TRANSLATIONS_SUCCEED,
			LocationActionTypes.GET_COUNTRIES_SUCCEED,
		],
		settingCountryTranslations
	);
}

function* watchStateLoadAction() {
	yield takeEvery(
		[LocationActionTypes.GET_STATES_SUCCEED],
		settingStateTranslations
	);
}

function* watchLanguageLoadAction() {
	yield takeEvery(
		[ReferencesActionTypes.GET_LANGUAGES_SUCCEED],
		settingLanguages
	);
}

function* watchDepartmentsLoadAction() {
	yield takeEvery(
		[ReferencesActionTypes.GET_DEPARTMENTS_SUCCEED],
		settingDepartments
	);
}

function* watchAreasOfInterestLoadAction() {
	yield takeEvery(
		[ReferencesActionTypes.GET_AREAS_OF_INTEREST_SUCCEED],
		settingAreasOfInterest
	);
}

function* watchAreaOfInterestLoadAction() {
	yield takeEvery(
		[ReferencesActionTypes.GET_AREA_OF_INTEREST_SUCCEED],
		settingAreaOfInterest
	);
}

function* watchCompanyTypesLoadAction() {
	yield takeEvery(
		[ReferencesActionTypes.GET_COMPANY_TYPES_SUCCEED],
		settingCompanyTypes
	);
}

function* watchCallingCodesLoadAction() {
	yield takeEvery(
		[LocationActionTypes.GET_CALLING_CODES_SUCCEED],
		settingCallingCodes
	);
}

function* watchTimezonesLoadAction() {
	yield takeEvery(
		[LocationActionTypes.GET_TIMEZONES_SUCCEED],
		settingTimezones
	);
}

function* progressiveFieldsSagas() {
	yield all([
		fork(watchForChangesInNeededFieldsAction),
		fork(watchOnChangeFieldChannelChannel),
		fork(watchContriesLoadAction),
		fork(watchStateLoadAction),
		fork(watchLanguageLoadAction),
		fork(watchDepartmentsLoadAction),
		fork(watchAreasOfInterestLoadAction),
		fork(watchAreaOfInterestLoadAction),
		fork(watchCompanyTypesLoadAction),
		fork(watchCallingCodesLoadAction),
		fork(watchTimezonesLoadAction),
	]);
}

const getFieldFromState = function<T extends FieldMetadata>(
	userRelatedFields: FieldMetadata[],
	fieldConfig: T
): T | undefined {
	const fields = userRelatedFields.filter(
		f => f.name === fieldConfig.name
	);
	if (!fields.length) return;

	return fields[0] as typeof fieldConfig;
};

export default progressiveFieldsSagas;
