import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import settings from '../../config/settings';
import { getTranslationUi } from '../Fields/TranslationUi';
import { connect } from 'react-redux';
import { AppState } from '../../store';

const useStyles = makeStyles(theme => ({
	appBar: {
		backgroundColor: colors.webexuiDarkGrayish,
		top: 'auto',
		bottom: 0,
		[theme.breakpoints.down('xs')]: {
			position: 'static',
		},
	},
	toolbar: {
		flexWrap: 'wrap',
		minHeight: 72,
	},
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	ul: {
		listStyle: 'none',
		margin: 0,
		padding: '0 50px',
		[theme.breakpoints.down('xs')]: {
			padding: '0 20px 35px'
		},
		'&>li': {
			display: 'inline-block',
			[theme.breakpoints.down('xs')]: {
				display: 'block',
				margin: '12px 0',
			},
			'&>a': {
				color: `${colors.webexuiWhite} !important`,
				cursor: 'pointer',
				textDecoration: 'none',
				fontSize: '16px',
				lineHeight: '19px',
				marginRight: 30,
				'&:hover': {
					color: '#676767 !important'
				}
			},
		},
	}
}));

const mapStateToProps = (appState: AppState) => ({
	legalInfo: appState.legalInfo,
	application: appState.application
});

type Props = ReturnType<typeof mapStateToProps>;

const Footer: React.FC<Props> = props => {
	const classes = useStyles();
	const { legalInfo, application } = props;
	const links = [
		{
			text: getTranslationUi('footer.privacy_policy', 'Privacy policy'),
			url: legalInfo && legalInfo.dip_privacy_policy.url,
		},
		{
			text: getTranslationUi(
				'footer.terms_of_use',
				'Terms of use'
			),
			url: legalInfo && legalInfo.dip_terms_and_conditions.url,
		},
		{
			text: getTranslationUi(
				'footer.general_information',
				'General information'
			),
			url: settings.footerLinks.generalInformationURL,
		},
		{
			text: getTranslationUi('footer.cookies', 'Cookies'),
			url: '#',
			onClick: (ev: React.MouseEvent<HTMLElement>) => {
				/* istanbul ignore next */
				ev.preventDefault();
				/* istanbul ignore next */
				if (
					window &&
					(window as any).CookieConsent &&
					(window as any).CookieConsent.show
				) {
					(window as any).CookieConsent.show();
				}
			}
		},
		{
			text: getTranslationUi(
				'footer.faq',
				'FAQ'
			),
			url: `${settings.footerLinks.faqURL}?app_id=${application.application.app_id}`,
		},
	];
	return (
		<React.Fragment>
			<CssBaseline />
			<AppBar position="static" elevation={0} className={classes.appBar}>
				<Toolbar className={classes.toolbar} disableGutters={true}>
					<ul className={classes.ul}>
						{links.map((link, idx) => {
							return (
								<li key={idx} >
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={link.url}
										onClick={link.onClick}
									>
										{link.text}
									</a>
								</li>
							);
						})}
					</ul>
				</Toolbar>
			</AppBar>
		</React.Fragment>
	);
};
export default connect(mapStateToProps)(Footer);
