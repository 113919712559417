import { all, fork, takeEvery, call, put, select } from 'redux-saga/effects';
import { AppState } from '../index';
import { getType } from 'typesafe-actions';
import { ApplicationApiModel, getDipLegalInfoData } from '../../api/applicationDataApi';
import { Token } from '../token/types';
import { FetchLegalInfo } from './actions';
import {
	ProcessToken,
	ProcessTokenFailed,
	ProcessTokenSuceeded,
} from '../token/actions';
import { LegalInfoActionTypes } from './types';

function* fetchLegalInfo(action: ProcessTokenFailed | ProcessTokenSuceeded) {
	try {
		const token: Token = yield select((state: AppState) => state.token);

		if (
			action.type === getType(ProcessToken.failure) ||
			!token ||
			!token.accessToken
		) {
			yield put(
				FetchLegalInfo.failure(
					new Error('Token is not valid to retrieve application')
				)
			);
			return;
		}

		const currentCulture: string = yield select(
			(state: AppState) => state.cultures.currentCulture
		);
		const response: ApplicationApiModel = yield call(
			getDipLegalInfoData,
			currentCulture
		);

		yield put(FetchLegalInfo.success(response));
	} catch (err) {
		yield put(FetchLegalInfo.success({dip_terms_and_conditions: {latest_version: ''}, dip_privacy_policy: {latest_version: ''}, privacy_policy: {latest_version: ''}}));
	}
}

function* watchLegalInfoFetch() {
	yield takeEvery(
		LegalInfoActionTypes.FETCH_LEGAL_INFO,
		fetchLegalInfo
	);
}

function* legalInfoSagas() {
	yield all([fork(watchLegalInfoFetch)]);
}

export default legalInfoSagas;
