import { FormFieldType } from './FormFieldType';
import { LegalInfoFieldUi } from './Components/LegalInfo/LegalInfo';

export const termsAndConditions: LegalInfoFieldUi = {
	name: 'terms_and_conditions',
	data_type: FormFieldType.Checkbox,
	label: {
		defaultText: 'I agree to',
	},
	labelLink: {
		defaultText: 'Terms & Conditions',
	},
	value: false,
	required: true,
	excludeFromPayload: true,
	disabled: false
};

export const danfossDipPrivacyPolicy: LegalInfoFieldUi = {
	name: 'danfoss_dip_privacy_policy',
	data_type: FormFieldType.Checkbox,
	label: {
		translationKey: 'login_signup.terms_policy.i_have_read_and_understood',
		defaultText: 'I have read and understood the XX',
	},
	labelLink: {
		translationKey: 'login_signup.terms_policy.privacy_policy',
		defaultText: 'Danfoss Privacy Policy',
	},
	labelLinkPlaceholder: 'policy_link',
	value: false,
	required: true,
	excludeFromPayload: true,
	disabled: false
};

export const privacyPolicy: LegalInfoFieldUi = {
	name: 'privacy_policy',
	data_type: FormFieldType.Checkbox,
	label: {
		translationKey: 'login_signup.terms_policy.i_have_read_and_understood',
		defaultText: 'I have read and understood the XX',
	},
	labelLink: {
		translationKey: 'login_signup.terms_policy.app_privacy_policy',
		defaultText: 'App Privacy Policy'
	},
	labelLinkPlaceholder: 'policy_link',
	value: false,
	required: true,
	excludeFromPayload: true,
	disabled: false
};

export const danfossDipTermsAndConditions: LegalInfoFieldUi = {
	name: 'danfoss_dip_terms_and_conditions',
	data_type: FormFieldType.Checkbox,
	label: {
		translationKey: 'login_signup.terms_policy.i_agree_to_terms',
		defaultText: 'I agree to',
	},
	labelLink: {
		translationKey: 'login_signup.terms_policy.terms_and_conditions',
		defaultText: 'Danfoss Terms and Conditions'
	},
	labelLinkPlaceholder: 'terms_and_conditions_link',
	value: false,
	required: true,
	excludeFromPayload: true,
	disabled: true
};

export const piplTerms: LegalInfoFieldUi = {
	name: 'pipl_terms',
	data_type: FormFieldType.Checkbox,
	label: {
		translationKey: 'login_signup.terms_policy.i_agree_to_pipl_terms',
		defaultText: 'I agree to',
	},
	labelLink: {
		translationKey: 'login_signup.terms_policy.pipl_terms',
		defaultText: 'PIPL terms',
	},
	labelLinkPlaceholder: 'pipl_terms_link',
	value: false,
	required: false,
	excludeFromPayload: true,
	disabled: false
};