import React, { Dispatch, useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import {
	danfossDipTermsAndConditions as danfossDipTermsAndConditionsProps,
	termsAndConditions as termsAndConditionsProps,
	danfossDipPrivacyPolicy as danfossDipPrivacyPolicyProps,
	privacyPolicy as privacyPolicyProps,
	piplTerms as piplTermsProps
} from '../Fields/FormFields/FieldsConfig';
import * as immutable from 'object-path-immutable';
import DynamicForm from './DynamicForm';
import { UpdateUserRequest, UpdateUser, ProgressiveProfilingSucceded } from '../../store/profile/actions';
import { Profile } from '../../store/profile/types';
import { getDefaultValuesObject } from '../../store/progressiveFields/ProgressiveFieldsService';
import {
	getFormValues,
	FormAction,
	formValueSelector,
} from 'redux-form';
import {
	SET_COUNTRIES_NEEDED,
	SET_STATES_NEEDED,
	GetStates,
	SET_TIMEZONES_NEEDED,
} from '../../store/location/actions';
import { FormFieldType } from '../Fields/FormFields/FormFieldType';
import { LegalInfo } from '../../api/userProfileDataApi';
import { FormLoading, LoadingErrorAction } from '../../store/loading/actions';
import { SET_LANGUAGES_NEEDED } from '../../store/references/actions';
import { pushEvent } from '../../services/analyticsService';
import { FieldMetadata } from '../../store/fieldsMetadata/types';
import { useNavigate, NavigateFunction } from "react-router-dom";
import { Spinner } from '@danfoss/webex-ui';

const formName = 'DynamicForm';
const formSelector = formValueSelector(formName);

const mapStateToProps = (appState: AppState) => ({
	application: appState.application,
	latestDanfossDipTermsAndConditions: appState.legalInfo?.dip_terms_and_conditions,
	latestTermsAndConditions: appState.application.terms_and_conditions,
	latestDanfossDipPrivacyPolicy: appState.legalInfo?.dip_privacy_policy,
	latestPrivacyPolicy: appState.application.privacy_policy,
	latestPiplTerms: appState.legalInfo?.pipl_terms,
	profile: appState.profile,
	loading: appState.loading,
	tokens: appState.token,
	form: appState.form,
	formValues: getFormValues(formName)(appState),
	location: appState.location,
	currentCulture: appState.cultures.currentCulture,
	countryFieldValue: formSelector(
		appState,
		appState.fieldsMetadata.fieldsObject.country.name
	) as string,
	fieldsMetadata: appState.fieldsMetadata,
	progressiveFields: appState.progressiveFields,
	initialValues: getDefaultValuesObject(appState.progressiveFields.fields),
	legalInfo: appState.legalInfo,
});

const mapDispatchToProps = (
	dispatch: Dispatch<
		| UpdateUserRequest
		| SET_COUNTRIES_NEEDED
		| SET_STATES_NEEDED
		| SET_LANGUAGES_NEEDED
		| SET_TIMEZONES_NEEDED
		| FormAction
	>
) => ({
	sendSucceed: () => dispatch(ProgressiveProfilingSucceded()),
	updateUserProfileRequest: (profile: Partial<Profile>, navigate: NavigateFunction) =>
		dispatch(UpdateUser.request({ profile, navigate })),
	loadStates: (countryCode: string) => dispatch(GetStates(countryCode)),
	loadingStart: () => {
		dispatch(LoadingErrorAction()); // Clear errors
		dispatch(FormLoading());
	},
});

interface FormContainerProps {
	piplTermsIsNeeded: boolean,
	danfossDipTermsAndConditionIsNeeded: boolean,
	termsAndConditionIsNeeded: boolean,
	danfossDipPrivacyPolicyIsNeeded: boolean,
	privacyPolicyIsNeeded: boolean
}

type Props = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	FormContainerProps;

const getIsLegalInfoNeeded = (danfoss_dip_privacy_policy: any, privacy_policy: any, terms_and_conditions: any, danfoss_dip_terms_and_conditions: any, pipl_terms: any) => {
	return danfoss_dip_privacy_policy || privacy_policy || terms_and_conditions || danfoss_dip_terms_and_conditions || pipl_terms
}

const FormContainer: React.FC<Props> = props => {

	const navigate = useNavigate()
	
	useEffect(() => {
		// Push an event that a user entered the dynamic form page
		pushEvent(
			{
				'event': 'virtualPageview',
				'page': `${(window as any).location.hostname}/addInfo/${props.application.application.name}`
			}
		);
	}, []);

	const onSubmit = (data: any) => {
		const {
			latestPrivacyPolicy: latestPrivacyPol,
			latestDanfossDipPrivacyPolicy: latestDanfossDipPrivacyPol,
			latestTermsAndConditions: latestTermsAndCond,
			latestDanfossDipTermsAndConditions: latestDanfossDipTermsAndCond,
			latestPiplTerms,
			privacy_policy,
			terms_and_conditions,
			danfoss_dip_terms_and_conditions,
			danfoss_dip_privacy_policy,
			pipl_terms
		} = data;

		const { fieldsMetadata } = props;
		const legalInformaion: LegalInfo = {};
		const isLegalInfoNeeded = getIsLegalInfoNeeded(danfoss_dip_privacy_policy, privacy_policy, terms_and_conditions, danfoss_dip_terms_and_conditions, pipl_terms);

		danfoss_dip_terms_and_conditions &&
			(legalInformaion.accepted_dip_terms_and_conditions_version =
				latestDanfossDipTermsAndCond &&
				latestDanfossDipTermsAndCond.latest_version);

		danfoss_dip_privacy_policy && (legalInformaion.accepted_dip_privacy_policy_version = latestDanfossDipPrivacyPol?.latest_version);

		pipl_terms && (legalInformaion.accepted_pipl_terms_version = latestPiplTerms && latestPiplTerms.latest_version);

		(terms_and_conditions || privacy_policy || (danfoss_dip_privacy_policy && !latestPrivacyPolicy.latest_version)) &&
			!isMyAccount &&
			(legalInformaion.application = {
				client_id: props.tokens!.clientId,
				accepted_app_terms_and_conditions_version: latestTermsAndCond?.latest_version,
				accepted_app_privacy_policy_version: latestPrivacyPolicy?.latest_version,
				is_dip_privacy_policy_accepted: (!privacy_policy ? danfoss_dip_privacy_policy : undefined),
			});

		let idpData: Partial<Profile> | undefined;

		for (const key in data) {
			if (data.hasOwnProperty(key)) {

				let element = data[key];
				let ppf = fieldsMetadata.fields
				ppf.push({ name: danfossDipTermsAndConditionsProps.name, excludeFromPayload: danfossDipTermsAndConditionsProps.excludeFromPayload, data_type: danfossDipTermsAndConditionsProps.data_type } as FieldMetadata);
				ppf.push({ name: danfossDipPrivacyPolicyProps.name, excludeFromPayload: danfossDipPrivacyPolicyProps.excludeFromPayload, data_type: danfossDipPrivacyPolicyProps.data_type } as FieldMetadata);
				ppf.push({ name: termsAndConditionsProps.name, excludeFromPayload: termsAndConditionsProps.excludeFromPayload, data_type: termsAndConditionsProps.data_type } as FieldMetadata);
				ppf.push({ name: privacyPolicyProps.name, excludeFromPayload: privacyPolicyProps.excludeFromPayload, data_type: privacyPolicyProps.data_type } as FieldMetadata);
				ppf.push({ name: piplTermsProps.name, excludeFromPayload: piplTermsProps.excludeFromPayload, data_type: piplTermsProps.data_type } as FieldMetadata);

				const fieldNameArray = fieldsMetadata.fields.filter(f => f.name === key);

				const field =
					fieldNameArray && fieldNameArray.length && fieldNameArray[0];

				if (!field) {
					continue;
				}

				if (!field.excludeFromPayload) {
					if (field.data_type === FormFieldType.Select) {
						if (field.is_multi_select) {
							idpData = immutable.set(
								idpData,
								`${field.name}_ids`,
								element.filter((e: string) => e !== '')
							);
						} else {
							idpData = immutable.set(
								idpData,
								`${field.name}_id`,
								Array.isArray(element) ? element[0] : element
							);
						}
					} else {
						idpData = immutable.set(
							idpData,
							field.name,
							element
						);
					}

				}

				isLegalInfoNeeded && (idpData = { ...idpData, legal_info: legalInformaion });
			}
		}

		// Push an event that a user clicked the signup button 
		pushEvent(
			{
				'event': 'formSubmit',
				'step': 'addInfo',
				'application': `${props.application.application.name}`
			}
		);

		if (!idpData) return;
		props.loadingStart();
		props.updateUserProfileRequest(idpData, navigate);
	};

	const userRelatedFields = props.progressiveFields.fields;
	const {
		latestTermsAndConditions,
		formValues,
		latestPrivacyPolicy,
		latestDanfossDipPrivacyPolicy,
		latestDanfossDipTermsAndConditions,
		latestPiplTerms,
		loading,
		tokens: { skip_accept_of_pipl_terms = true } = {},
		legalInfo: legal_info
	} = props;

	const isMyAccount =
		new URLSearchParams(window.location.search).get('isMyAccount') === 'true';

	const appName = props.application.application.name;
	const danfossDipPrivacyPolicyAvailable = latestDanfossDipPrivacyPolicy?.latest_version;
	const privacyPolicyAvailable = latestPrivacyPolicy?.latest_version;
	const dipTermsUAvailable = latestDanfossDipTermsAndConditions && latestDanfossDipTermsAndConditions.latest_version;
	const appTermsAvailable = latestTermsAndConditions && latestTermsAndConditions.latest_version;
	const piplTermsAvailable = latestPiplTerms && latestPiplTerms.latest_version;

	const legalInfo = {
		termsAndConditionsData: {
			termsAndConditionIsNeeded: props.termsAndConditionIsNeeded && appTermsAvailable,
			...latestTermsAndConditions,
			...termsAndConditionsProps,
			appName,
		},
		danfossDipTermsAndConditionsData: {
			danfossDipTermsAndConditionIsNeeded: props.danfossDipTermsAndConditionIsNeeded && dipTermsUAvailable,
			...latestDanfossDipTermsAndConditions,
			...danfossDipTermsAndConditionsProps,
			appName: 'Danfoss Profile',
		},
		danfossDipPrivacyPolicyData: {
			danfossDipPrivacyPolicyIsNeeded: props.danfossDipPrivacyPolicyIsNeeded && danfossDipPrivacyPolicyAvailable,
			...latestDanfossDipPrivacyPolicy,
			...danfossDipPrivacyPolicyProps,
			appName: '',
		},
		privacyPolicyData: {
			privacyPolicyIsNeeded: props.privacyPolicyIsNeeded && privacyPolicyAvailable,
			...latestPrivacyPolicy,
			...privacyPolicyProps,
			appName,
		},
		piplTermsData: {
			piplTermsIsNeeded: props.piplTermsIsNeeded && piplTermsAvailable,
			...latestPiplTerms,
			...piplTermsProps,
			appName: '',
		}
	};

	const isTermsAndConditionNeeded = legalInfo.termsAndConditionsData.termsAndConditionIsNeeded;
	const isDanfossDipPrivacyPolicyNeeded = legalInfo.danfossDipPrivacyPolicyData.danfossDipPrivacyPolicyIsNeeded;
	const isPrivacyPolicyNeeded = legalInfo.privacyPolicyData.privacyPolicyIsNeeded;
	const isDanfossDipTermsAndConditionNeeded = legalInfo.danfossDipTermsAndConditionsData.danfossDipTermsAndConditionIsNeeded;
	const isPiplTermsNeeded = legalInfo.piplTermsData.piplTermsIsNeeded;

	if (legal_info && !loading?.redirecting && userRelatedFields?.length === 0 && !isTermsAndConditionNeeded && !isDanfossDipPrivacyPolicyNeeded && !isPrivacyPolicyNeeded && !isDanfossDipTermsAndConditionNeeded && !isPiplTermsNeeded) {
		props.sendSucceed();
	}	

	return userRelatedFields?.length === 0 && !isTermsAndConditionNeeded && !isDanfossDipPrivacyPolicyNeeded && !isPrivacyPolicyNeeded && !isDanfossDipTermsAndConditionNeeded && !isPiplTermsNeeded ? 
		<Spinner visible={true} />
	: (
		<DynamicForm
			initialValues={{
				latestTermsAndConditions,
				latestDanfossDipTermsAndConditions,
				latestDanfossDipPrivacyPolicy,
				latestPrivacyPolicy,
				latestPiplTerms,
				legalInfo,
				fieldsMetadata: props.fieldsMetadata,
				applicationFields: props.application.fields,
				requireLinkClick: !skip_accept_of_pipl_terms && props.piplTermsIsNeeded,
				...props.initialValues
			}}
			userRelatedFields={userRelatedFields}
			formValues={formValues}
			loading={(userRelatedFields?.length === 0 && !isTermsAndConditionNeeded && !isDanfossDipPrivacyPolicyNeeded && !isPrivacyPolicyNeeded && !isDanfossDipTermsAndConditionNeeded && !isPiplTermsNeeded) ? true : loading}
			onSubmit={onSubmit}
		/>
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FormContainer);
