import { LoadingActionTypes, Loading } from './types';
import { LoadingActionType } from './actions';
import { createReducer } from 'typesafe-actions';

export const initialState: Loading = {
	formLoading: false,
	loading: false,
};

const reducer = createReducer<Loading, LoadingActionType>(initialState, {
	[LoadingActionTypes.LOADED]: (state, action) => {
		return { ...state, loading: false };
	},
	[LoadingActionTypes.LOADING]: (state, action) => {
		return { ...state, loading: true };
	},
	[LoadingActionTypes.REDIRECTING]: (state, action) => {
		return { ...state, redirecting: true };
	},
	[LoadingActionTypes.FORM_LOADING]: (state, action) => {
		return { ...state, formLoading: true };
	},
	[LoadingActionTypes.FORM_LOADED]: (state, action) => {
		return { ...state, formLoading: false };
	},
	[LoadingActionTypes.LOADING_ERROR]: (state, action) => {
		return { ...state, loadingError: action.payload };
	}
});

export { reducer as loadingReducer };
