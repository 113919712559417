import { Profile } from '../profile/types';
import { Field } from '../../api/applicationDataApi';
import { FieldUi } from '../../components/Fields/FormFields/FieldUi';

import { FormFieldType } from '../../components/Fields/FormFields/FormFieldType';
import { FieldMetadata, FieldsMetadata } from '../fieldsMetadata/types';

const getFieldIsOptionalValue = (applicationFields: FieldMetadata[], name: string) => {
	return !((applicationFields.find(appField => appField.name === name) || {}) as Field).is_optional || false;
}

export const getApplicationRelatedFields = (applicationFields: FieldMetadata[], is_dip_privacy_policy_accepted: boolean) => {
	let fields: FieldMetadata[] = applicationFields.map(field => {
		if(applicationFields.some(appField => field.name === appField.name)) {
			return {...field, required: getFieldIsOptionalValue(applicationFields, field.name)};
		}
		
		return field
	})
	
	if (is_dip_privacy_policy_accepted) {
		fields = fields.filter(field => field.required);
	}
	
	return fields;

};

export const getNotFilledInFields = (fields: FieldMetadata[], fieldsMetadata: FieldsMetadata, profile: Profile) => {
	return fieldsMetadata.fields.filter(field => {
		
		if (!field.idp_data_path) return false;

		/* istanbul ignore next */
		return businessLogic.some(fn => fn(field, fieldsMetadata, profile, fields))

	});
};

const isFieldDependingOnOtherField = (fieldsDependsOnName: string | undefined, allFields: FieldUi[], currentField: FieldUi, fieldMetadata: FieldMetadata) => {
	
	// If current field depends on another field and in the list of application fields
	if (fieldsDependsOnName) {
		// If dependent field in the list of required fields and current field is not in the list of required 
		// fields and if dependent Field requires current field value, then add the field as a required field
		if (allFields.some(field => field.name === fieldsDependsOnName) && 
			!allFields.some(field => field.name === currentField.name)) {
				
			if (fieldMetadata.is_data_required_if_dependend_field_has_value) {
				return true;
			}
		}
	}
}

const isFieldLocked = (name: string, profile: Profile) => {
	const connlockedFields = (profile.identities && profile.identities[0].metadata?.LockedProfileFields || "").split(",");

	return connlockedFields.indexOf(name) > -1
}

const isFieldValueEmpty = (value: any) : boolean => {
	return value === undefined || value === null || value === '' || (Array.isArray(value) && value.length === 0);
}

const handleFieldDependency = (
	currentField: FieldUi,
	fieldsMetadata: FieldsMetadata,
	profile: Profile,
	allFields: FieldUi[]) => {

		// Get field meta data for current field
		const fieldMetadata: FieldMetadata = fieldsMetadata.fieldsObject[currentField.name];
		const fieldsDependsOnName = fieldMetadata.display_when_field_has_value;
		
		const profileFieldValue = getProfileValueByField(
			fieldsMetadata.fieldsObject[currentField.name],
		 	profile
		);
		// If current field is in the list of fields to show and the current field has no profile value return
		if (allFields.some(field => field.name === currentField.name && field.idp_data_path) && isFieldValueEmpty(profileFieldValue)) {
			currentField.required = true;
			return true;
		}

		// If current field depends on another field and in the list of application fields
		if (isFieldDependingOnOtherField(fieldsDependsOnName, allFields, currentField, fieldMetadata)) {
			return true;
		}		

		let valueToReturn = false;
		
		// If the current field has a field in the list of required application fields and that requiered applicaiton
		// field has no value, then we need to display both the current field and the required application field. 
		allFields.forEach((field: FieldUi) => {

			// Get the application fields meta data
			const fMD: FieldMetadata = fieldsMetadata.fieldsObject[field.name];

			// If the application field should be displayed if the current field has a value (is being displayed)
			if (fMD.display_when_field_has_value === currentField.name) {
				
					const fieldValue = getProfileValueByField(
						fieldsMetadata.fieldsObject[fMD.name],
						profile
					);
					
					// if the requiered application field has no value, then we need to display the 
					// current field, becuase we ant to display both.
					if (!fieldValue) {
						currentField.defaultValue = profileFieldValue as string;
						currentField.value = profileFieldValue;						
						valueToReturn = true;
					}
			} 
		});

		// Loop all DIP profile fields to add any fields that is dependent of the current field
		// and is requiered if current field has a value and if the the current field is in the 
		// list of requiered application fields		
		fieldsMetadata.fields.forEach((field: FieldMetadata) => {
			
			if (field.display_when_field_has_value === currentField.name && 
				field.is_data_required_if_dependend_field_has_value && 
				allFields.some(f => f.name === currentField.name)
				) {
				
					const fieldValue = getProfileValueByField(
						fieldsMetadata.fieldsObject[field.name],
						profile
					);
					
					// Only add to the list if the field hasn't got a profile value
					if (!fieldValue) {
						currentField.defaultValue = profileFieldValue as string;
						currentField.value = profileFieldValue;
						valueToReturn = true;
					}
			} 
		});

		if (isFieldLocked(currentField.name, profile)) {
			currentField.required = false;
			currentField.disabled = true;
		}

		return valueToReturn;
}

export const getDefaultValuesObject = (fields: FieldMetadata[]) => {
	const defaultValuesFromFields = fields.filter(f => f.defaultValue);
	const fieldsAndDefaultValuesLocal: { [key: string]: string } = {};
	defaultValuesFromFields.forEach(field => {
		field.name &&
			field.defaultValue &&
			(fieldsAndDefaultValuesLocal[field.name] = field.defaultValue);
	});

	return fieldsAndDefaultValuesLocal;
};

export const removeExtraFields = (fields: FieldMetadata[], profile: Profile, fieldsObject: any) => {
	return fields.filter(field => {

		const callingCodeValue = getProfileValueByField(fieldsObject.calling_code, profile) || '';

		// We want the system to ask for calling code if required and only phone number present
		if (
			(field.name === fieldsObject.calling_code.name &&
			getProfileValueByField(fieldsObject.phone_number, profile)) && callingCodeValue !== ''
		) {
			return false;
		}
		return true;
	});
};

export const getProfileValueByField = (field: FieldMetadata, profile: Profile) => {
	const propertyPath = getPropertyPath(field, profile);

	try {
		return  getDeepValue(profile, propertyPath);
	} catch {
		return null;
	}
};

export const getPropertyPath = (field: FieldMetadata, profile: Profile) => {

	let fieldName = field.name;
  	
  	if(field.name === 'calling_code') {
  		
  		let callingCodeIdValue = undefined;
  		let callingCodeValue = undefined;
  		try{
  			callingCodeValue = getDeepValue(profile, field.name);
  			callingCodeIdValue = getDeepValue(profile, `${field.name}.id`);
  		} catch (error) {}
  
  		if (!callingCodeIdValue && callingCodeValue !== undefined) {
  			fieldName = 'phone_calling_code';
		} 
	}
  
	if (field.data_type === FormFieldType.Select) {
		if (!field.is_multi_select) {
			return `${fieldName}.id`;
		} else {
			return fieldName;
		}
	} else {
		return fieldName;
	}
};

export const getDeepValue = (obj: { [key: string]: any }, paths: string) => {
	return paths.split('.').reduce((innerObj, path) => innerObj[path], obj) as
		| any
		| { [key: string]: any };
};

export const blAddCountryIfStateNeeded = (
	currentField: FieldUi,
	fieldsObject: any,
	profile: Profile,
	allFields: FieldUi[]
) => {

	// BUG # 148260 : handling case when country is filled in, but state is not.

	// only for country field
	if (currentField.name !== fieldsObject.country.name) {
		return false;
	}
	// only when country is filled in
	const countryValue = getProfileValueByField(
		fieldsObject.country,
		profile
	);

	if (!countryValue) {
		return false;
	}

	// only when state is NOT filled in and state is required
	if (getProfileValueByField(fieldsObject.state, profile)) {
		return false;
	}

	if (!allFields.some(field => field.name === 'state' && field.required ))
		return false;

	currentField.defaultValue = countryValue as string;
	currentField.value = countryValue;

	const connlockedFields = (profile.identities && profile.identities[0].metadata?.LockedProfileFields || "").split(",");

	if (connlockedFields.indexOf(currentField.name) > -1) {
		currentField.required = false;
		currentField.disabled = true;
	}
	
	return true;
};

export const blAddPhoneAndCallingCode = (
	currentField: FieldUi,
	fieldsObject: any,
	profile: Profile
) => {
	if (
		currentField.name === fieldsObject.calling_code.name &&
		!getProfileValueByField(fieldsObject.phone_number, profile)
	) {
		const callingCodeValue = getProfileValueByField(
			fieldsObject.calling_code,
			profile
		);

		currentField.defaultValue = callingCodeValue as string;
		currentField.value = callingCodeValue;

		return true;
	}

	return false;
};

export const blAddPhoneIfCallingCodeNeeded = (
	currentField: FieldUi,
	fieldsObject: any,
	profile: Profile,
	allFields: FieldUi[]
) => {
	// only for phone number field
	if (currentField.name !== fieldsObject.phone_number.name) {
		return false;
	}
	
	const phoneValue = getProfileValueByField(
		fieldsObject.phone_number,
		profile
	);

	// only when phone number is filled in
	if (!phoneValue) {
		return false;
	}

	// only when calling_code is NOT filled in and calling_code is required
	if (getProfileValueByField(fieldsObject.calling_code, profile)) {
		return false;
	}

	if (!allFields.some(field => field.name === 'calling_code' && field.required ))
		return false;

	currentField.defaultValue = phoneValue as string;
	currentField.value = phoneValue;

	const connlockedFields = (profile.identities && profile.identities[0].metadata?.LockedProfileFields || "").split(",");

	if (connlockedFields.indexOf(currentField.name) > -1) {
		currentField.required = false;
		currentField.disabled = true;
	}
	return true;
};



const businessLogic = [
	handleFieldDependency
] as ((currentField: FieldMetadata, fieldsObject: any, profile?: Profile, allFields?: FieldMetadata[]) => boolean)[];
