import settings from '../config/settings';
import fetchApiWrapper from './fetchApiWrapper';
import { getRegistrationCenterApiKey } from '../utils';

export interface ApplicationApiModel {
	schema_version: number;
	application: Application;
	fields: Field[];
	terms_and_conditions: TermsAndConditions;
	privacy_policy: TermsAndConditions;
	dip_privacy_policy: TermsAndConditions;
	dip_terms_and_conditions: TermsAndConditions;
	pipl_terms: TermsAndConditions;
}
export interface DipLegalInfo {
	privacy_policy: TermsAndConditions;
	dip_privacy_policy: TermsAndConditions;
	dip_terms_and_conditions: TermsAndConditions;
	pipl_terms?: TermsAndConditions;
}

export interface Application {
	name: string;
	description: string;
	app_type: string;
	app_id: string;
}

export interface Value {
	label_key: string;
	default_label: string;
	value: string;
	selected: boolean;
}

export interface Properties {
	[key: string]: any;
}

export interface Field {
	api_url: string;
	name: string;
	data_type: string;
	display_name: string;
	idp_data_path: string;
	validation: any;
	is_optional: boolean;
	is_multi_select: boolean;
}

export interface TermsAndConditions {
	latest_version: string;
	url?: string;
}

export const getApplicationData = (
	accessToken: string,
	applicationId: string,
	pageLanguage?: string
): Promise<ApplicationApiModel> => {
	const requestHeaders = new Headers();
	requestHeaders.set(
		'Content-Type',
		'application/json-patch+json; charset=utf-8'
	);
	requestHeaders.append('Authorization', `BEARER ${accessToken}`);

	// fallback to 'en' in case culture is unknown or longer that 2 symbols
	let language = pageLanguage;
	if (!language || language.length > 2) {
		language = 'en';
	}
	requestHeaders.append('Accept-Language', language);
	return fetchApiWrapper(
		`${settings.backend.regcenter}/clients-metadata/${applicationId}`,
		{
			headers: requestHeaders,
			method: 'GET',
		}
	);
};

export const getDipLegalInfoData = (
	pageLanguage?: string
): Promise<ApplicationApiModel> => {
	const requestHeaders = new Headers();
	requestHeaders.set(
		'Content-Type',
		'application/json-patch+json; charset=utf-8'
	);
	
	requestHeaders.append('Authorization', getRegistrationCenterApiKey(settings.backend.hashKey));

	if (pageLanguage) {
		requestHeaders.append('Accept-Language', pageLanguage);
	}
	return fetchApiWrapper(`${settings.backend.regcenter}/dip-legal-info`, {
		headers: requestHeaders,
		method: 'GET',
	});
};
